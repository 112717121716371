import { VerticalLayout } from "ds_legacy/materials/layouts";
import { FormSectionsWrapper } from "dsl/ecosystems/PatientForms/components";
import { IntroSection } from "./IntroSection";
import { SectionFive } from "./SectionFive";
import { SectionFour } from "./SectionFour";
import { SectionOne } from "./SectionOne";
import { SectionSeven } from "./SectionSeven";
import { SectionSix } from "./SectionSix";
import { SectionThree } from "./SectionThree";
import { SectionTwo } from "./SectionTwo";

export function DRVMedicalFormPresenter() {
  return (
    <VerticalLayout width="100%">
      <FormSectionsWrapper>
        <IntroSection />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionOne />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionTwo />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionThree />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionFour />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionFive />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionSix />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionSeven />
      </FormSectionsWrapper>
    </VerticalLayout>
  );
}
