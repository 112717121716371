import { GUARDIAN } from "core/consts";
import {
  AHBpostleitzahlSchema,
  unixDateSchema,
} from "onprem/validation/AHBRequestPayload";
import { z } from "zod";

export const GUARDIAN_1 = 1 as const;
export const GUARDIAN_2 = 2 as const;

export const GKVGeneralFormSchema = z
  .object({
    patient_first_name: z.string().min(1).max(45),
    patient_last_name: z.string().min(1).max(45),
    patient_street: z.string().max(46).nullable().optional(),
    patient_housenumber: z.string().max(9).nullable().optional(),
    patient_zipcode: AHBpostleitzahlSchema.nullable().optional(),
    patient_city: z.string().min(1).max(40).nullable().optional(),
    patient_phone_number: z.string().min(1).max(25).nullable().optional(),
    insurance_number: z.string().min(10).max(12),
    patient_birthdate: unixDateSchema,

    general_practitioner_first_name: z.string().max(45).nullable().optional(),
    general_practitioner_last_name: z.string().max(45).nullable().optional(),
    general_practitioner_street: z.string().max(46).nullable().optional(),
    general_practitioner_housenumber: z.string().max(9).nullable().optional(),
    general_practitioner_zipcode: AHBpostleitzahlSchema.nullable().optional(), // or use postleitzahlSchema
    general_practitioner_city: z.string().max(40).nullable().optional(),
    general_practitioner_phone_number: z.string().max(25).nullable().optional(),

    insured_details_guardian_1_type: z
      .nativeEnum(GUARDIAN)
      .nullable()
      .optional(),
    insured_details_guardian_1_first_name: z
      .string()
      .max(45)
      .nullable()
      .optional(),
    insured_details_guardian_1_last_name: z
      .string()
      .max(45)
      .nullable()
      .optional(),
    insured_details_guardian_1_street: z.string().max(46).nullable().optional(),
    insured_details_guardian_1_house_number: z
      .string()
      .max(9)
      .nullable()
      .optional(),
    insured_details_guardian_1_zipcode: z
      .string()
      .max(10)
      .nullable()
      .optional(),
    insured_details_guardian_1_city: z.string().max(40).nullable().optional(),
    insured_details_guardian_1_phone: z.string().max(25).nullable().optional(),

    insured_details_guardian_2_type: z
      .nativeEnum(GUARDIAN)
      .nullable()
      .optional(),
    insured_details_guardian_2_first_name: z
      .string()
      .max(45)
      .nullable()
      .optional(),
    insured_details_guardian_2_last_name: z
      .string()
      .max(45)
      .nullable()
      .optional(),
    insured_details_guardian_2_street: z.string().max(46).nullable().optional(),
    insured_details_guardian_2_house_number: z
      .string()
      .max(9)
      .nullable()
      .optional(),
    insured_details_guardian_2_zipcode: z
      .string()
      .max(10)
      .nullable()
      .optional(),
    insured_details_guardian_2_city: z.string().max(40).nullable().optional(),
    insured_details_guardian_2_phone: z.string().max(25).nullable().optional(),

    social_worker_first_name: z.string().min(1).max(45).nullable().optional(),
    social_worker_last_name: z.string().min(1).max(45),
    social_worker_phone_number: z.string().min(1).max(25),
    social_worker_fax_number: z.string().min(1).max(25).nullable().optional(),
  })
  .superRefine((data, ctx) => {
    const validateGuardian = (
      prefix: typeof GUARDIAN_1 | typeof GUARDIAN_2,
    ) => {
      // General Practitioner
      const gpFirst = data.general_practitioner_first_name?.trim();
      const gpLast = data.general_practitioner_last_name?.trim();
      const hasGP = !!gpFirst || !!gpLast;

      if (hasGP) {
        if (!gpLast) {
          ctx.addIssue({
            path: ["general_practitioner_last_name"],
            code: z.ZodIssueCode.custom,
            message: "Nachname ist erforderlich, wenn Hausarzt angegeben ist",
          });
        }
        if (!gpFirst) {
          ctx.addIssue({
            path: ["general_practitioner_first_name"],
            code: z.ZodIssueCode.custom,
            message: "Vorname ist erforderlich, wenn Hausarzt angegeben ist",
          });
        }
        if (!data.general_practitioner_city) {
          ctx.addIssue({
            path: ["general_practitioner_city"],
            code: z.ZodIssueCode.custom,
            message: "Ort ist erforderlich, wenn Hausarzt angegeben ist",
          });
        }
      }

      // Guardian
      const type = data[`insured_details_guardian_${prefix}_type`];
      const first = data[`insured_details_guardian_${prefix}_first_name`];
      const last = data[`insured_details_guardian_${prefix}_last_name`];

      // Validate if any one of the 3 is present
      const hasAny = !!type || !!first || !!last;

      if (hasAny) {
        if (!type) {
          ctx.addIssue({
            path: [`insured_details_guardian_${prefix}_type`],
            code: z.ZodIssueCode.custom,
            message: `Vertretungstyp ist erforderlich, wenn Name angegeben ist`,
          });
        }
        if (!first) {
          ctx.addIssue({
            path: [`insured_details_guardian_${prefix}_first_name`],
            code: z.ZodIssueCode.custom,
            message: `Vorname ist erforderlich, wenn Vertretungstyp angegeben ist`,
          });
        }
        if (!last) {
          ctx.addIssue({
            path: [`insured_details_guardian_${prefix}_last_name`],
            code: z.ZodIssueCode.custom,
            message: `Nachname ist erforderlich, wenn Vertretungstyp angegeben ist`,
          });
        }
      }
    };

    validateGuardian(GUARDIAN_1);
    validateGuardian(GUARDIAN_2);
  });
