import { QueryProgress } from "core/types";
import React, { useState } from "react";
import { RSButtonProps } from "../RSButton";

export type ConfirmationDialogProps<T> = {
  cancelText?: string;
  componentContent?: React.ReactNode;
  confirmButtonProps?: Partial<Omit<RSButtonProps, "onClick">>;
  content?: string;
  dialogFooter?: React.ReactNode;
  id?: string;
  nodeContent?: React.ReactNode;
  onCancel: () => void;
  onSubmit: (data?: T) => void;
  opened: boolean;
  queryProgress?: QueryProgress;
  submitText?: string;
  title: React.ReactNode | string;
};

/**
 * Provides a way to use the `ConfirmationDialog` component programmatically,
 * similar to `window.confirm`, by calling a `confirm` function that returns a Promise.
 * This makes it easy to reuse a single confirmation dialog across multiple parts of a component.
 *
 * The `confirm` function accepts an optional `dialogSettings` parameter,
 * allowing customization of the dialog's content, buttons, and behavior for each call.
 *
 * **Note:** Asynchronous confirmation does not work with externally controlled state via `queryProgress`.
 * The dialog will close immediately after the user confirms or cancels.
 * It's up to the implementation to handle the confirmed or rejected outcome.
 *
 * @returns A tuple containing:
 *   - The props needed to control a `ConfirmationDialog` component.
 *   - A function (`confirm`) that triggers the dialog and returns a Promise with the user's choice.
 */
export function useConfirmationDialog<T = boolean>() {
  const [props, setProps] = useState<ConfirmationDialogProps<T>>({
    onCancel: () => {},
    onSubmit: () => {},
    opened: false,
    title: "",
  });

  function confirm(
    dialogSettings: Partial<ConfirmationDialogProps<T>> = {},
  ): Promise<T extends boolean ? boolean : T | undefined> {
    return new Promise((resolve) => {
      function resolveDialog(result: T) {
        resolve(result as T extends boolean ? boolean : T | undefined);
        setProps((current) => ({ ...current, opened: false }));
      }

      setProps((current) => ({
        ...current,
        ...dialogSettings,
        opened: true,
        onCancel: () => {
          dialogSettings.onCancel?.();
          resolveDialog(undefined as T);
          setProps((current) => ({ ...current, opened: false }));
        },
        onSubmit: (data?: T) => {
          dialogSettings.onSubmit?.(data as T);
          resolveDialog((data ?? true) as T);
        },
      }));
    });
  }

  return [props, confirm] as const;
}
