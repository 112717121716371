import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import { CHECKBOX_SIZE } from "core/consts";
import { CUSTOM_BLACK, WHITE } from "ds_legacy/materials/colors";
import {
  FONT_SIZE_14,
  FONT_WEIGHT_SEMI_BOLD,
} from "ds_legacy/materials/typography";
import { usePrint } from "dsl/atoms/Contexts";
import React, { CSSProperties } from "react";
import { FormElementRenderProps, isValid } from "react-forms-state/src";
import { useTranslations } from "translations";
import { getCheckboxStyle } from "../CheckboxInputField";
import { getHelperText } from "../Validation";

export type CheckboxOptionV2 = {
  label: string;
  value: string;
};

export type CheckboxGroupV2Props = {
  elementName: string;
  flatModel?: boolean;
  formControlLabelSx?: CSSProperties;
  formLabelSx?: CSSProperties;
  label: string;
  options: CheckboxOptionV2[];
  required?: boolean;
};

export default function CheckboxGroupV2({
  elementName,
  flatModel,
  formControlLabelSx,
  formLabelSx,
  label,
  options,
  required,
}: CheckboxGroupV2Props) {
  const translations = useTranslations();
  const print = usePrint();
  return (
    <FormElementRenderProps elementName={elementName} flatModel={flatModel}>
      {({ onChange, validation, value }) => {
        const currentValue: string[] = Array.isArray(value) ? value : [];
        const errorMessage = getHelperText({
          hasCustomValidation: true,
          translations,
          validation,
        });

        const handleCheckboxChange = (
          event: React.ChangeEvent<HTMLInputElement>,
        ) => {
          const { checked, value } = event.target;

          if (checked) {
            onChange([...currentValue, value]);
          } else {
            onChange(currentValue.filter((v) => v !== value));
          }
        };

        return (
          <FormControl
            required={required}
            error={!isValid(validation)}
            component="fieldset"
            variant="standard"
          >
            <FormLabel
              component="legend"
              sx={{
                color: CUSTOM_BLACK,
                fontWeight: FONT_WEIGHT_SEMI_BOLD,
                ...formLabelSx,
              }}
            >
              {label}
            </FormLabel>
            <FormGroup>
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  control={
                    <Checkbox
                      checked={currentValue.includes(option.value)}
                      color="primary"
                      inputProps={
                        {
                          ["data-testid"]: `checkbox-${elementName}-${option.value}`,
                        } as CheckboxProps["inputProps"]
                      }
                      name={elementName}
                      onChange={handleCheckboxChange}
                      sx={{
                        fontSize: FONT_SIZE_14,
                        "& .MuiSvgIcon-root": {
                          backgroundColor: WHITE,
                        },
                        ...getCheckboxStyle({
                          print,
                          isGroup: true,
                          validation,
                          checkboxSize: CHECKBOX_SIZE.SMALL,
                        }),
                      }}
                      value={option.value}
                    />
                  }
                  sx={{
                    fontSize: FONT_SIZE_14,
                    ...formControlLabelSx,
                  }}
                  label={option.label}
                />
              ))}
            </FormGroup>
            {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
          </FormControl>
        );
      }}
    </FormElementRenderProps>
  );
}
