import {
  PREDICAMENT_STATE_NO,
  PREDICAMENT_STATE_YES,
  REHAB_DIRECTIVE_TYPE,
  TRANSPORTATION_SUBTYPE,
  TRANSPORTATION_TYPE,
} from "core/consts";
import { OPERATION_INDEXES } from "onprem/mapping/mapFormToRehabRequest";
import {
  ICDCodeSchema,
  ICDReasonSchema,
  unixDateSchema,
} from "onprem/validation/AHBRequestPayload";
import { z } from "zod";

const isFilled = (val: unknown) => {
  if (Array.isArray(val))
    return val.some((v) => typeof v === "string" && v.trim().length > 0);
  if (typeof val === "string") return val.trim().length > 0;
  return val !== null && val !== undefined;
};

export function getPerItemValidationMessage(
  validation: { infos?: string } | undefined,
  index: number,
): string | undefined {
  try {
    const parsed = JSON.parse(validation?.infos ?? "");
    if (parsed?.type === "perItemErrors" && Array.isArray(parsed.messages)) {
      return parsed.messages[index] || undefined;
    }
  } catch {
    return undefined;
  }
}

export const GKVMedicalFormSchema = z
  .object({
    gender: z.number(),
    patient_first_name: z.string().min(1).max(45),
    patient_last_name: z.string().min(1).max(45),
    insurance_number: z.string().min(10).max(12),
    patient_birthdate: unixDateSchema,

    rehab_directive_state: z.union([
      z.literal(PREDICAMENT_STATE_YES),
      z.literal(PREDICAMENT_STATE_NO),
    ]),
    rehab_directive_type: z
      .array(z.nativeEnum(REHAB_DIRECTIVE_TYPE))
      .min(1)
      .max(12)
      .nullable()
      .optional(),

    transport_type: z.nativeEnum(TRANSPORTATION_TYPE),
    transport_type_taxi_specification: z
      .nativeEnum(TRANSPORTATION_SUBTYPE)
      .nullable()
      .optional(),
    transport_type_ambulance_specification: z
      .nativeEnum(TRANSPORTATION_SUBTYPE)
      .nullable()
      .optional(),
    require_accompanying_person: z.union([
      z.literal(PREDICAMENT_STATE_YES),
      z.literal(PREDICAMENT_STATE_NO),
    ]),

    icd_code_1_diagnosis: z.string().max(150).optional().nullable(),
    icd_code_1_code: ICDCodeSchema.optional().nullable(),
    icd_code_1_reason: ICDReasonSchema.optional().nullable(),

    icd_code_2_diagnosis: z.string().max(150).optional().nullable(),
    icd_code_2_code: ICDCodeSchema.optional().nullable(),
    icd_code_2_reason: ICDReasonSchema.optional().nullable(),

    icd_code_3_diagnosis: z.string().max(150).optional().nullable(),
    icd_code_3_code: ICDCodeSchema.optional().nullable(),
    icd_code_3_reason: ICDReasonSchema.optional().nullable(),

    icd_code_4_diagnosis: z.string().max(150).optional().nullable(),
    icd_code_4_code: ICDCodeSchema.optional().nullable(),
    icd_code_4_reason: ICDReasonSchema.optional().nullable(),

    icd_code_5_diagnosis: z.string().max(150).optional().nullable(),
    icd_code_5_code: ICDCodeSchema.optional().nullable(),
    icd_code_5_reason: ICDReasonSchema.optional().nullable(),

    surgery_performed: z
      .union([
        z.literal(PREDICAMENT_STATE_YES),
        z.literal(PREDICAMENT_STATE_NO),
      ])
      .nullable()
      .optional(),

    ...Object.fromEntries(
      Object.values(OPERATION_INDEXES).flatMap((index) => [
        [`operation_${index}_date`, unixDateSchema.nullable().optional()],
        [`operation_${index}_ops`, z.array(z.string()).nullable().optional()],
        [
          `operation_${index}_wound_closed`,
          z
            .union([
              z.literal(PREDICAMENT_STATE_YES),
              z.literal(PREDICAMENT_STATE_NO),
            ])
            .nullable()
            .optional(),
        ],
      ]),
    ),

    doctor_in_charge_in_hospital_first_name: z.string().min(1).max(45),
    doctor_in_charge_in_hospital_last_name: z.string().min(1).max(45),
    doctor_in_charge_in_hospital_phone: z.string().min(1).max(25),
    // doctor_in_charge_in_hospital_date: unixDateSchema, #TODO missing field in the form
  })
  .superRefine((data, ctx) => {
    //  Fall nach § 16 Reha-RL gegeben
    if (
      data.rehab_directive_state === PREDICAMENT_STATE_YES &&
      (!data.rehab_directive_type || data.rehab_directive_type.length === 0)
    ) {
      ctx.addIssue({
        path: ["rehab_directive_type"],
        code: z.ZodIssueCode.custom,
        message: "At least one rehab directive type is required.",
      });
    }

    // Transport
    if (
      data.transport_type === TRANSPORTATION_TYPE.TAXI &&
      !data.transport_type_taxi_specification
    ) {
      ctx.addIssue({
        path: ["transport_type_taxi_specification"],
        message: "This field is required for taxi or ambulance transport.",
        code: z.ZodIssueCode.custom,
      });
    }
    if (
      data.transport_type === TRANSPORTATION_TYPE.AMBULANCE &&
      !data.transport_type_ambulance_specification
    ) {
      ctx.addIssue({
        path: ["transport_type_ambulance_specification"],
        message: "This field is required for taxi or ambulance transport.",
        code: z.ZodIssueCode.custom,
      });
    }

    // ICD Codes

    let completeGroupCount = 0;

    for (let i = 1; i <= 5; i++) {
      const diagnosis = data[`icd_code_${i}_diagnosis`];
      const code = data[`icd_code_${i}_code`];
      const reason = data[`icd_code_${i}_reason`];

      const diagnosisFilled = isFilled(diagnosis);
      const codeFilled = isFilled(code);
      const reasonFilled = isFilled(reason);

      const filled = [diagnosisFilled, codeFilled, reasonFilled].filter(
        Boolean,
      ).length;

      if (filled === 3) {
        completeGroupCount++;
      } else if (filled > 0 && filled < 3) {
        // Only show error on missing fields
        if (!diagnosisFilled) {
          ctx.addIssue({
            path: [`icd_code_${i}_diagnosis`],
            code: z.ZodIssueCode.custom,
            message: "Required if other fields in this row are filled.",
          });
        }
        if (!codeFilled) {
          ctx.addIssue({
            path: [`icd_code_${i}_code`],
            code: z.ZodIssueCode.custom,
            message: "Required if other fields in this row are filled.",
          });
        }
        if (!reasonFilled) {
          ctx.addIssue({
            path: [`icd_code_${i}_reason`],
            code: z.ZodIssueCode.custom,
            message: "Required if other fields in this row are filled.",
          });
        }
      }
    }

    if (completeGroupCount === 0) {
      // Add errors only to fields in the first row to simulate a form-level error
      if (!isFilled(data.icd_code_1_diagnosis)) {
        ctx.addIssue({
          path: ["icd_code_1_diagnosis"],
          code: z.ZodIssueCode.custom,
          message: "At least one complete diagnosis row is required.",
        });
      }
      if (!isFilled(data.icd_code_1_code)) {
        ctx.addIssue({
          path: ["icd_code_1_code"],
          code: z.ZodIssueCode.custom,
          message: "At least one complete diagnosis row is required.",
        });
      }
      if (!isFilled(data.icd_code_1_reason)) {
        ctx.addIssue({
          path: ["icd_code_1_reason"],
          code: z.ZodIssueCode.custom,
          message: "At least one complete diagnosis row is required.",
        });
      }
    }

    // Surgeries
    let completeOperationCount = 0;

    for (const index of Object.values(OPERATION_INDEXES)) {
      const date = data[`operation_${index}_date`];
      const ops = data[`operation_${index}_ops`];
      const wound = data[`operation_${index}_wound_closed`];

      const hasDate = isFilled(date);
      const hasWound = isFilled(wound);
      const hasOPS = Array.isArray(ops) && ops.some(isFilled);

      const filledFields = [hasDate, hasWound, hasOPS].filter(Boolean).length;

      if (filledFields === 3) {
        completeOperationCount++;
      } else if (filledFields > 0) {
        // Only show field-level errors on missing ones
        if (!hasDate) {
          ctx.addIssue({
            path: [`operation_${index}_date`],
            code: z.ZodIssueCode.custom,
            message: "Required if other fields in this operation are filled.",
          });
        }
        if (!hasWound) {
          ctx.addIssue({
            path: [`operation_${index}_wound_closed`],
            code: z.ZodIssueCode.custom,
            message: "Required if other fields in this operation are filled.",
          });
        }
        if (!hasOPS) {
          ctx.addIssue({
            path: [`operation_${index}_ops`],
            code: z.ZodIssueCode.custom,
            message:
              "At least one OPS code is required if other fields are filled.",
          });
        }
      }

      // Validate each OPS code: must be a string and max 8 characters
      if (Array.isArray(ops)) {
        const messages: (string | null)[] = Array(ops.length).fill(null);

        ops.forEach((code, i) => {
          if (!isFilled(code)) return;

          if (typeof code !== "string") {
            messages[i] = "Each OPS code must be a string.";
          } else if (code.length > 8) {
            messages[i] = "Each OPS code must be max. 8 characters.";
          }
        });

        if (messages.some(Boolean)) {
          ctx.addIssue({
            path: [`operation_${index}_ops`],
            code: z.ZodIssueCode.custom,
            message: JSON.stringify({ type: "perItemErrors", messages }),
          });
        }
      }
    }

    // If surgery is marked as performed but no full operation is present
    if (
      data.surgery_performed === PREDICAMENT_STATE_YES &&
      completeOperationCount === 0
    ) {
      const firstIndex = OPERATION_INDEXES.OP1;

      const firstDate = data[`operation_${firstIndex}_date`];
      const firstOps = data[`operation_${firstIndex}_ops`];
      const firstWound = data[`operation_${firstIndex}_wound_closed`];

      if (!isFilled(firstDate)) {
        ctx.addIssue({
          path: [`operation_${firstIndex}_date`],
          code: z.ZodIssueCode.custom,
          message: "At least one complete operation is required.",
        });
      }

      if (!isFilled(firstWound)) {
        ctx.addIssue({
          path: [`operation_${firstIndex}_wound_closed`],
          code: z.ZodIssueCode.custom,
          message: "At least one complete operation is required.",
        });
      }

      if (!Array.isArray(firstOps) || !firstOps.some(isFilled)) {
        ctx.addIssue({
          path: [`operation_${firstIndex}_ops`],
          code: z.ZodIssueCode.custom,
          message: "At least one complete operation is required.",
        });
      }
    }
  });
