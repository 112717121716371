import {
  AT_HOME_SITUATION,
  HOME_CARE_SITUATION,
  LIVING_SITUATION_ALONE,
  LIVING_SITUATION_CAREHOME,
  LIVING_SITUATION_IN_ELDERLY_HOME,
  LIVING_SITUATION_SHARED_FLAT,
  LIVING_SITUATION_WITH_FAMILY,
  REHAB_FORM_NAMES,
} from "core/consts";
import { GetOntologyType, PredicamentState } from "core/types";
import Translations from "translations/types";
import { RehabFormFormGetters } from "../configs";
import { auctionBirthdatetoUnix, booleanToPredicamentState } from "../utils";

export const importAuctionToGKVGeneralForm = (
  _getOntology: GetOntologyType,
  _translations: Translations,
): RehabFormFormGetters<typeof REHAB_FORM_NAMES.GKV_GENERAL> => ({
  patient_first_name: (auction) =>
    auction?.patient?.profile?.first_name?.decrypted,
  patient_last_name: (auction) =>
    auction?.patient?.profile?.last_name?.decrypted,
  patient_birthdate: auctionBirthdatetoUnix,
  insurance_number: (auction) =>
    auction.patient.profile?.financing?.insurance_number?.decrypted ?? "",
  living_lift: (auction) =>
    auction.patient.profile?.lift_available_state as PredicamentState,
  living_lift_description: (auction) =>
    auction.patient.profile?.lift_available_description ?? null,
  living_situation: (auction) => {
    const livingSituation = auction.patient.profile?.living_situation;

    switch (livingSituation) {
      case LIVING_SITUATION_ALONE:
        return HOME_CARE_SITUATION.HOME;
      case LIVING_SITUATION_WITH_FAMILY:
        return HOME_CARE_SITUATION.HOME;
      case LIVING_SITUATION_CAREHOME:
        return HOME_CARE_SITUATION.CARE_HOME;
      case LIVING_SITUATION_IN_ELDERLY_HOME:
      case LIVING_SITUATION_SHARED_FLAT:
        return HOME_CARE_SITUATION.ASSISTED_LIVING;
      default:
        return null;
    }
  },
  living_situation_at_home: (auction) => {
    const livingSituation = auction.patient.profile?.living_situation;

    switch (livingSituation) {
      case LIVING_SITUATION_ALONE:
        return AT_HOME_SITUATION.ALONE;
      case LIVING_SITUATION_WITH_FAMILY:
        return AT_HOME_SITUATION.RELATIVES;
      default:
        return null;
    }
  },
  domestic_situation_is_safe: (auction) =>
    auction.patient.profile?.unsafe_current_domestic_situation_state ?? null,
  hospital_name: (auction) => auction.patient.careseeker?.name ?? null,
  hospital_address: (auction) =>
    [
      auction.patient.careseeker?.address?.street,
      auction.patient.careseeker?.address?.street_number,
    ]
      .truthy()
      .join(" "),
  hospital_zipcode_city: (auction) =>
    [
      auction.patient.careseeker?.address?.zipcode,
      auction.patient.careseeker?.address?.city,
    ]
      .truthy()
      .join(", "),
  case_id: (auction) => auction.patient.external_id ?? null,
  social_worker_first_name: (auction) =>
    auction.patient.social_worker?.first_name,
  social_worker_last_name: (auction) =>
    auction.patient.social_worker?.last_name,
  social_worker_fax_number: (auction) =>
    auction.patient.social_worker?.fax_number ?? null,
  social_worker_phone_number: (auction) =>
    auction.patient.social_worker?.phone ?? null,
  insurance_company_name: (auction) =>
    auction?.patient.profile?.financing?.insurance?.name ?? null,
  patient_street: (auction) => auction.profile?.search_location?.street,
  patient_housenumber: (auction) =>
    auction.profile?.search_location?.encrypted_house_number?.decrypted,
  patient_zipcode: (auction) => auction.profile?.search_location?.zipcode,
  patient_city: (auction) => auction.profile?.search_location?.city,
  communication_in_german_possible: (auction) => {
    const cannotSpeakGerman =
      auction.patient.profile?.communication
        ?.patient_cannot_communicate_in_german;
    if (cannotSpeakGerman == null) {
      return null;
    }
    return booleanToPredicamentState(!cannotSpeakGerman);
  },
  communication_other_language: (auction) =>
    auction.patient.profile?.communication?.patient_language || null,
});
