import {
  REHAB_FORM_NAMES,
  REQUESTED_REHAB_SERVICE,
  REQUESTED_REHAB_SERVICE_TYPE,
  SOLUTION_REHAB_DAY_HOSPITALIZATION,
  SOLUTION_REHAB_FULL_HOSPITALIZATION,
  SOLUTION_REHAB_MOBILE,
  SPECIALIZATION_ONCOLOGY,
} from "core/consts";
import { getName } from "core/model/accounts";
import { Auction, GetOntologyType, RequestedRehaService } from "core/types";
import { RehabFormFormGetters } from "../configs";
import {
  auctionBirthdatetoUnix,
  getHospitalAddress,
  getHospitalName,
  getPatientCity,
  getPatientStreetHouseNumber,
} from "../utils";

const checkRequestedRehabServices = ({
  auction,
  withOncology,
}: {
  auction: Auction;
  withOncology?: boolean;
}) => {
  const rehabSolutions = [
    SOLUTION_REHAB_MOBILE,
    SOLUTION_REHAB_DAY_HOSPITALIZATION,
    SOLUTION_REHAB_FULL_HOSPITALIZATION,
  ];

  const hasRehabSolution = auction.solutions?.some((solution) =>
    rehabSolutions.includes(solution),
  );

  if (!hasRehabSolution) return false;

  if (withOncology) {
    return auction.specializations?.some((specialization) =>
      [SPECIALIZATION_ONCOLOGY].includes(specialization),
    );
  }

  return true;
};

const checkRequestedRehabServiceType = (auction: Auction) => {
  const rehabSolutionTypes = {
    outpatient: [SOLUTION_REHAB_MOBILE, SOLUTION_REHAB_DAY_HOSPITALIZATION],
    inpatient: [SOLUTION_REHAB_FULL_HOSPITALIZATION],
  };

  if (
    auction.solutions?.some((s) => rehabSolutionTypes.outpatient.includes(s))
  ) {
    return REQUESTED_REHAB_SERVICE_TYPE.OUTPATIENT;
  }

  if (
    auction.solutions?.some((s) => rehabSolutionTypes.inpatient.includes(s))
  ) {
    return REQUESTED_REHAB_SERVICE_TYPE.INPATIENT;
  }

  return null;
};

const getRequestedRehabService = (auction: Auction) => {
  const isOncology = checkRequestedRehabServices({
    auction,
    withOncology: true,
  });
  const isInsured = checkRequestedRehabServices({ auction });

  if (isOncology) {
    return REQUESTED_REHAB_SERVICE.RELATIVES;
  }

  if (isInsured) {
    return REQUESTED_REHAB_SERVICE.INSURED;
  }

  return null;
};

const getRequestedRehabServiceType =
  (rehabService: RequestedRehaService) => (auction: Auction) => {
    const requestedRehabService: RequestedRehaService | null =
      getRequestedRehabService(auction);

    if (!requestedRehabService || requestedRehabService !== rehabService)
      return null;

    return checkRequestedRehabServiceType(auction);
  };

export const importAuctionToDRVGeneralForm = (
  getOntology: GetOntologyType,
): RehabFormFormGetters<typeof REHAB_FORM_NAMES.DRV_GENERAL> => ({
  insurance_name: (auction: Auction) =>
    auction?.payers?.insurance?.name ?? null,
  patient_birthdate: auctionBirthdatetoUnix,
  pension_insurance_company_name: (auction: Auction) =>
    auction?.payers?.public_pension?.name ?? null,
  patient_street_housenumber: getPatientStreetHouseNumber,
  requested_rehab_service: getRequestedRehabService,
  requested_rehab_service_type_insured: getRequestedRehabServiceType(
    REQUESTED_REHAB_SERVICE.INSURED,
  ),
  requested_rehab_service_type_relatives: getRequestedRehabServiceType(
    REQUESTED_REHAB_SERVICE.RELATIVES,
  ),
  insurance_type: (auction: Auction) =>
    auction?.payers?.insurance?.type ?? null,
  hospital_name_address: (auction: Auction) =>
    [getHospitalName(auction), getHospitalAddress(auction)].truthy().join(" "),
  social_worker_name_number: (auction: Auction) =>
    [
      getName(auction.patient.social_worker, getOntology),
      auction.patient.social_worker?.phone,
      auction.patient.social_worker?.fax_number,
    ]
      .truthy()
      .join(", "),
  gender_extended: (auction: Auction) =>
    auction?.patient?.profile?.gender ?? null,
  patient_first_name: (auction: Auction) =>
    auction?.patient?.profile?.first_name?.decrypted ?? null,
  patient_last_name: (auction: Auction) =>
    auction?.patient?.profile?.last_name?.decrypted ?? null,
  patient_zipcode: (auction: Auction) =>
    auction.profile?.search_location?.zipcode ?? null,
  patient_city: getPatientCity,
});
