import {
  GENDER_DIVERSE,
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_UNSPECIFIED,
} from "core/consts";
import RadioGroupV2, { RadioOptionV2 } from "ds_legacy/components/RadioGroupV2";
import { RADIO_WHITE_BACKGROUND } from "ds_legacy/materials/colors";
import { padding, sizing } from "ds_legacy/materials/metrics";
import { FONT_SIZE_14, FONT_WEIGHT_BOLD } from "ds_legacy/materials/typography";
import {
  DatePickerWithLabel,
  Section,
  SectionRow,
  TextInputWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const SectionTwo = () => {
  const translations = useTranslations();

  const options: RadioOptionV2[] = [
    {
      id: GENDER_MALE,
      value: GENDER_MALE,
      label: translations.patientForms.drvGeneralForm.sectionTwo.male,
    },
    {
      id: GENDER_FEMALE,
      value: GENDER_FEMALE,
      label: translations.patientForms.drvGeneralForm.sectionTwo.female,
    },
    {
      id: GENDER_DIVERSE,
      value: GENDER_DIVERSE,
      label: translations.patientForms.drvGeneralForm.sectionTwo.diverse,
    },
    {
      id: GENDER_UNSPECIFIED,
      value: GENDER_UNSPECIFIED,
      label: translations.patientForms.drvGeneralForm.sectionTwo.unknown,
    },
  ];

  return (
    <Section title={translations.patientForms.drvGeneralForm.sectionTwo.title}>
      <SectionRow customPadding={padding(1, 0, 0)}>
        <TextInputWithLabel
          bold
          label={translations.patientForms.drvGeneralForm.sectionTwo.firstName}
          large
          elementName="patient_first_name"
        />
        <TextInputWithLabel
          bold
          label={translations.patientForms.drvGeneralForm.sectionTwo.lastName}
          large
          elementName="patient_last_name"
        />
      </SectionRow>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={translations.patientForms.drvGeneralForm.sectionTwo.maidenName}
          large
          elementName="patient_birth_name"
        />
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.drvGeneralForm.sectionTwo.formerNames
          }
          large
          elementName="patient_maiden_name"
        />
      </SectionRow>
      <SectionRow>
        <RadioGroupV2
          elementName="gender_extended"
          formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
          formLabelSx={{
            padding: padding(1, 0),
            fontSize: FONT_SIZE_14,
            fontWeight: FONT_WEIGHT_BOLD,
          }}
          options={options}
          label={translations.patientForms.drvGeneralForm.sectionTwo.gender}
          radioSx={RADIO_WHITE_BACKGROUND}
        />
      </SectionRow>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={translations.patientForms.drvGeneralForm.sectionTwo.street}
          large
          elementName="patient_street_housenumber"
        />
        <TextInputWithLabel
          bold
          label={translations.patientForms.drvGeneralForm.sectionTwo.zipCode}
          elementName="patient_zipcode"
        />
        <TextInputWithLabel
          bold
          label={translations.patientForms.drvGeneralForm.sectionTwo.city}
          large
          elementName="patient_city"
        />
      </SectionRow>
      <SectionRow>
        <DatePickerWithLabel
          bold
          label={
            translations.patientForms.drvMedicalForm.sectionZero
              .dateBirthPatient
          }
          elementName="patient_birthdate"
        />
        <TextInputWithLabel
          bold
          label={translations.patientForms.drvGeneralForm.sectionTwo.phone}
          elementName="patient_phone_number"
        />
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.drvGeneralForm.sectionTwo.nationality
          }
          large
          elementName="patient_nationality"
        />
      </SectionRow>
    </Section>
  );
};
