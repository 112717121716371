import {
  DRV_TRAVEL_TYPES,
  OUTPATIENT_NOT_POSSIBLE_REASONS,
  REHAB_FORM_NAMES,
  SOLUTION_REHAB_DAY_HOSPITALIZATION,
  SOLUTION_REHAB_FULL_HOSPITALIZATION,
  SOLUTION_REHAB_MOBILE,
  TRANSPORT_TYPE_AMBULANCE_LYING_DOWN,
  TRANSPORT_TYPE_AMBULANCE_SITTING,
  TRANSPORT_TYPE_TAXI,
} from "core/consts";
import { getName } from "core/model/accounts";
import {
  Auction,
  DrvTransportType,
  GetOntologyType,
  OutPatientNotPossibleReason,
} from "core/types";
import Translations from "translations/types";
import { RehabFormFormGetters } from "../configs";
import {
  auctionBirthdatetoUnix,
  booleanToPredicamentState,
  DRG_CODE_CHARACTER_LIMIT,
  getHospitalAddress,
  getHospitalName,
  getICDCode,
  getInfection,
  getPatientCity,
  getPatientStreetHouseNumber,
  IK_NUMBER_CHARACTER_LIMIT,
  INFECTIONS_CHARACTER_LIMIT,
} from "../utils";

const needsCatheter = (auction: Auction) =>
  auction.patient?.care_needs?.access_drainages &&
  (auction.patient.care_needs.access_drainages.central_venous_catheter !=
    null ||
    auction.patient.care_needs.access_drainages.peripheral_venous_catheter !=
      null ||
    auction.patient.care_needs.access_drainages.suprapubic_catheter != null ||
    auction.patient.care_needs.access_drainages.permanent_catheter != null);

export const importAuctionToDRVMedicalForm = (
  getOntology: GetOntologyType,
  translations: Translations,
): RehabFormFormGetters<typeof REHAB_FORM_NAMES.DRV_MEDICAL> => ({
  admission_date: (auction: Auction) => auction.patient.profile?.admission_date,
  complications: (auction: Auction) =>
    auction.patient?.diagnosis?.hospital_stay?.complications ?? null,
  current_therapy: (_auction: Auction) => null,
  day_reha: (auction: Auction) =>
    auction?.solutions?.some((s) =>
      [SOLUTION_REHAB_MOBILE, SOLUTION_REHAB_DAY_HOSPITALIZATION].includes(s),
    ),
  discharge_date: (auction: Auction) =>
    auction.patient?.profile?.discharge_date ?? null,
  drg_code: (auction: Auction) =>
    auction.patient?.drg_code?.slice(0, DRG_CODE_CHARACTER_LIMIT) ?? null,
  eat_without_help: (auction: Auction) =>
    auction.patient.care_needs?.support?.eating ?? null,
  fecal_incontinence: (auction: Auction) =>
    booleanToPredicamentState(
      auction.patient?.care_needs?.incontinence?.fecal_incontinence != null,
    ),
  full_reha: (auction: Auction) =>
    auction?.solutions?.includes(SOLUTION_REHAB_FULL_HOSPITALIZATION),
  hospital_bank_holder: getHospitalName,
  hospital_bank_name: (auction: Auction) =>
    auction.patient?.careseeker?.banking_details?.bank_name ?? null,
  hospital_billing_address: getHospitalAddress,
  hospital_iban: (auction: Auction) =>
    auction.patient?.careseeker?.banking_details?.iban ?? null,
  hospital_ik_number: (auction: Auction) =>
    auction.patient?.careseeker?.banking_details?.ik_number?.slice(
      0,
      IK_NUMBER_CHARACTER_LIMIT,
    ) ?? null,
  hospital_name_address: (auction: Auction) =>
    [getHospitalName(auction), getHospitalAddress(auction)].truthy().join(" "),
  icd_code_1_code: (auction: Auction) => getICDCode(auction, 1),
  icd_code_2_code: (auction: Auction) => getICDCode(auction, 2),
  icd_code_3_code: (auction: Auction) => getICDCode(auction, 3),
  infections: (auction: Auction) =>
    getInfection(auction, getOntology, translations)?.slice(
      0,
      INFECTIONS_CHARACTER_LIMIT,
    ),
  infections_selected: (auction: Auction) =>
    booleanToPredicamentState(
      getInfection(auction, getOntology, translations) != null,
    ),
  main_diagnosis: (auction: Auction) =>
    auction.patient?.diagnosis?.main_diagnosis ?? null,
  needs_catheter: needsCatheter,
  needs_catheter_selected: (auction: Auction) =>
    booleanToPredicamentState(!!needsCatheter(auction)),
  patient_birthdate: auctionBirthdatetoUnix,
  patient_zipcode: (auction: Auction) =>
    auction.profile?.search_location?.zipcode ?? null,
  patient_city: getPatientCity,
  patient_height: (auction: Auction) =>
    auction.patient.profile?.height?.decrypted ?? null,
  patient_name: (auction: Auction) =>
    [
      auction.patient?.profile?.last_name?.decrypted ?? null,
      auction.patient?.profile?.first_name?.decrypted ?? null,
    ]
      .truthy()
      .join(", "),
  patient_street_housenumber: getPatientStreetHouseNumber,
  patient_weight: (auction: Auction) =>
    auction.patient.profile?.weight?.decrypted ?? null,
  secondary_diagnosis: (auction: Auction) =>
    auction.patient?.diagnosis?.secondary_diagnosis ?? null,
  secondary_diagnosis_selected: (auction: Auction) =>
    booleanToPredicamentState(
      !!auction.patient?.diagnosis?.secondary_diagnosis,
    ),
  transfer_date: (auction: Auction) => auction.start_date ?? null,
  transport_type: (auction: Auction) =>
    (auction.patient.transport?.transport_type?.filter((type) =>
      Object.values(DRV_TRAVEL_TYPES).includes(type as DrvTransportType),
    ) as DrvTransportType[]) ?? null,
  urinary_incontinence: (auction: Auction) =>
    booleanToPredicamentState(
      auction.patient.care_needs?.incontinence?.urinary_incontinence != null,
    ),
  social_worker_name_number: (auction: Auction) =>
    [
      getName(auction.patient.social_worker, getOntology),
      auction.patient.social_worker?.phone,
      auction.patient.social_worker?.fax_number,
    ]
      .truthy()
      .join(", "),
  carelevel_selected: (auction: Auction) =>
    booleanToPredicamentState(
      auction.patient.profile?.financing?.carelevel?.level != null &&
        auction.patient.profile.financing.carelevel.level >= 0,
    ),
  carelevel: (auction: Auction) =>
    auction.patient.profile?.financing?.carelevel?.level,
  outpatient_not_possible_reason: (auction: Auction) => {
    const outpatientTypes: OutPatientNotPossibleReason[] = [];

    if (
      auction.patient.transport?.transport_type?.some((type) =>
        [
          TRANSPORT_TYPE_AMBULANCE_LYING_DOWN,
          TRANSPORT_TYPE_AMBULANCE_SITTING,
        ].includes(type),
      )
    ) {
      outpatientTypes.push(OUTPATIENT_NOT_POSSIBLE_REASONS.AMBULANCE);
    }

    if (
      auction.patient.transport?.transport_type?.includes(TRANSPORT_TYPE_TAXI)
    ) {
      outpatientTypes.push(OUTPATIENT_NOT_POSSIBLE_REASONS.TAXI);
    }

    if (auction.patient.transport?.require_accompanying_person) {
      outpatientTypes.push(OUTPATIENT_NOT_POSSIBLE_REASONS.ACCOMPANYING_PERSON);
    }

    return outpatientTypes;
  },
});
