import { FormControl, FormGroup, SxProps } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import {
  FUNCTION_DIAGNOSIS_PRIORITIES,
  MUSCOSKELETAL_RESILIENCE,
  PREDICAMENT_STATE_YES,
} from "core/consts";
import ConnectedCheckboxGroup from "ds_legacy/components/CheckboxGroup";
import CheckboxGroupV2 from "ds_legacy/components/CheckboxGroupV2";
import CheckboxInputField from "ds_legacy/components/CheckboxInputField";
import { SubheadingFormLabel } from "ds_legacy/components/FormComponents/SubheadingFormLabel";
import RadioGroupV2 from "ds_legacy/components/RadioGroupV2";
import { TextInputField } from "ds_legacy/components/TextInputField";
import { RADIO_WHITE_BACKGROUND, WHITE } from "ds_legacy/materials/colors";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { margin, padding, sizing } from "ds_legacy/materials/metrics";
import {
  Body,
  FONT_SIZE_14,
  FONT_SIZE_16,
  FONT_SIZE_18,
  FONT_WEIGHT_BOLD,
  Subheading,
} from "ds_legacy/materials/typography";
import {
  CheckboxWithTextfield,
  DatePickerWithLabel,
  Footnote,
  FootnoteDescription,
  FootnoteLabel,
  FormSectionsWrapper,
  GAP,
  LARGE_INPUT_WIDTH,
  PatientFormsSectionHeader,
  PatientFormSubheading,
  Section,
  SectionRow,
  SelectWithLabel,
  SMALL_INPUT_MIN_WIDTH,
  TextAreaWithLabel,
  TextInputWithLabel,
  YesNoRadioWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { SingerAssessment } from "dsl/organisms/IndependenceScale";
import {
  NUMBER_OF_OPS_FIELDS,
  OPERATION_INDEXES,
} from "onprem/mapping/mapFormToRehabRequest";
import { FormElementRenderProps, FormWatcher } from "react-forms-state/src";
import { useTranslations } from "translations";
import Translations from "translations/types";
import { getActivableStyles, ICD_CODE_CHARACTER_LIMIT } from "../utils";
import {
  useComplicationOptions,
  useEarlyRehabCriteriaOptions,
  useGenderOptions,
  useICDReasonOptions,
  useInfectionOptions,
  useMusculoskeletalResilienceOptions,
  useRehabAdmissionOptions,
  useRehabFocusOptions,
  useRehabTypeDirectiveOptions,
  useRehabTypeOptions,
  useSpecialRehabRequirementsItems,
  useTransportOptions,
} from "./hooks";

const FOOTNOTE: Footnote = {
  superText: 1,
  description: (translations: Translations) =>
    translations.patientForms.medicalForm.sectionTwo.footnoteOne,
  label: (translations: Translations) =>
    translations.patientForms.medicalForm.sectionTwo.icdCode,
};

export function GKVMedicalFormPresenter({
  onChange,
}: {
  onChange: (value: any, statePath: string) => void;
}) {
  const translations = useTranslations();
  const icdReasonOptions = useICDReasonOptions();
  const genderOptions = useGenderOptions();
  const rehabAdmissionOptions = useRehabAdmissionOptions();
  const infectionOptions = useInfectionOptions();
  const complicationOptions = useComplicationOptions();
  const musculoskeletalResilienceOptions =
    useMusculoskeletalResilienceOptions();
  const rehabFocusOptions = useRehabFocusOptions();
  const rehabTypeOptions = useRehabTypeOptions();
  const earlyRehabCriteriaOptions = useEarlyRehabCriteriaOptions();
  const rehabTypeDirectiveOptions = useRehabTypeDirectiveOptions();
  const specialRehabRequirementsItems = useSpecialRehabRequirementsItems();
  const transportOptions = useTransportOptions();

  return (
    <VerticalLayout width="100%">
      <FormSectionsWrapper>
        <PatientFormsSectionHeader>
          {translations.patientForms.medicalForm.sectionOne.title}
        </PatientFormsSectionHeader>
        <Section>
          <Subheading
            as="h5"
            margin={margin(0, 0, 0.5, 0.5)}
            style={{
              fontSize: FONT_SIZE_18,
              fontWeight: FONT_WEIGHT_BOLD,
            }}
          >
            {
              translations.patientForms.medicalForm.sectionOne
                .informationInsuredPerson
            }
          </Subheading>
          <RadioGroupV2
            elementName="gender"
            label={
              translations.patientForms.medicalForm.sectionOne
                .accessibilityGender
            }
            options={genderOptions}
            formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
            formLabelSx={visuallyHidden as SxProps}
            radioGroupSx={{ flexDirection: "row", paddingTop: sizing(1) }}
            radioSx={RADIO_WHITE_BACKGROUND}
            required
          />
          <SectionRow customPadding={padding(1, 0, 0)}>
            <TextInputWithLabel
              bold
              label={translations.patientForms.medicalForm.sectionOne.firstName}
              large
              elementName="patient_first_name"
              required
            />
            <TextInputWithLabel
              bold
              label={translations.patientForms.medicalForm.sectionOne.lastName}
              large
              elementName="patient_last_name"
              required
            />
          </SectionRow>
          <SectionRow>
            <DatePickerWithLabel
              bold
              label={
                translations.patientForms.generalForm.insuredPerson.dateOfBirth
              }
              elementName="patient_birthdate"
              inputSx={{
                width: "100%",
                minWidth: SMALL_INPUT_MIN_WIDTH,
              }}
              wrapperSx={{ width: LARGE_INPUT_WIDTH }}
              required
            />
            <TextInputWithLabel
              bold
              label={
                translations.patientForms.generalForm.insuredPerson
                  .insuranceNumber
              }
              large
              elementName="insurance_number"
              required
            />
          </SectionRow>
          <PatientFormSubheading>
            {
              translations.patientForms.medicalForm.sectionOne
                .hospitalStayInformation
            }
          </PatientFormSubheading>
          <SectionRow customPadding={padding(1, 0, 0)}>
            <DatePickerWithLabel
              bold
              elementName="admission_date"
              inputSx={{
                width: "100%",
                minWidth: SMALL_INPUT_MIN_WIDTH,
              }}
              label={
                translations.patientForms.medicalForm.sectionOne.intakeDate
              }
              wrapperSx={{ width: LARGE_INPUT_WIDTH }}
            />
            <DatePickerWithLabel
              bold
              elementName="discharge_date"
              inputSx={{
                width: "100%",
                minWidth: SMALL_INPUT_MIN_WIDTH,
              }}
              label={
                translations.patientForms.medicalForm.sectionOne.dischargeDate
              }
              wrapperSx={{ width: LARGE_INPUT_WIDTH }}
            />
          </SectionRow>
          <PatientFormSubheading withBottomPadding>
            {
              translations.patientForms.medicalForm.sectionOne
                .earlyRehabMeasures
            }
          </PatientFormSubheading>
          <YesNoRadioWithLabel
            elementName="early_rehabilitation_measures"
            label={
              translations.patientForms.medicalForm.sectionOne
                .earlyRehabMeasures
            }
            formLabelSx={visuallyHidden}
            startWithNo
          />
          <PatientFormSubheading withBottomPadding>
            {translations.patientForms.medicalForm.sectionOne.admissionRehab}
          </PatientFormSubheading>
          <RadioGroupV2
            elementName="rehab_admission_type"
            label={
              translations.patientForms.generalForm.sectionI
                .accessibilityGuardianType
            }
            options={rehabAdmissionOptions}
            formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
            formLabelSx={visuallyHidden as SxProps}
            radioSx={RADIO_WHITE_BACKGROUND}
            sideMutation={(_value, mutation) => {
              mutation(null, "rehab_admission_outside_two_weeks_explanation");
              mutation(null, "rehab_admission_outside_two_weeks_start_date");
              mutation(null, "rehab_admission_within_two_weeks_start_date");
            }}
          />
        </Section>
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <PatientFormsSectionHeader>
          {translations.patientForms.medicalForm.sectionTwo.title}
        </PatientFormsSectionHeader>
        <Section>
          <Subheading
            as="h5"
            margin={margin(0, 0, 0.5, 0.5)}
            style={{
              fontSize: FONT_SIZE_18,
              fontWeight: FONT_WEIGHT_BOLD,
            }}
          >
            {translations.patientForms.medicalForm.sectionTwo.functionDiagnoses}
          </Subheading>
          {Object.values(FUNCTION_DIAGNOSIS_PRIORITIES).map((priority) => (
            <SectionRow
              customPadding={padding(2, 0, 0)}
              key={priority}
              style={{ flexWrap: "nowrap", alignItems: "baseline" }}
            >
              <Body
                as="p"
                margin={margin(0, 0, 1)}
                style={{ alignSelf: "center" }}
              >
                {priority}
              </Body>
              <TextInputWithLabel
                bold
                elementName={`icd_code_${priority}_diagnosis`}
                label={
                  translations.patientForms.medicalForm.sectionTwo
                    .diagnosisDescription
                }
                labelWrapperSx={{
                  width: "40%",
                  minWidth: undefined,
                }}
                required={priority === 1}
              />
              <TextInputWithLabel
                bold
                characterLimit={ICD_CODE_CHARACTER_LIMIT}
                elementName={`icd_code_${priority}_code`}
                label={<FootnoteLabel footnote={FOOTNOTE} />}
                labelWrapperSx={{
                  width: "20%",
                  minWidth: undefined,
                }}
                required={priority === 1}
              />
              <SelectWithLabel
                bold
                elementName={`icd_code_${priority}_reason`}
                label={translations.patientForms.medicalForm.sectionTwo.cause}
                options={icdReasonOptions}
                wrapperSx={{ width: "40%" }}
                inputSx={{
                  width: "100%",
                  fontSize: FONT_SIZE_14,
                  minWidth: undefined,
                }}
                formHelperTextSx={{ display: "none" }}
                required={priority === 1}
              />
            </SectionRow>
          ))}
          <VerticalLayout
            style={{
              padding: padding(GAP, 0, 0),
              boxSizing: "border-box",
            }}
          >
            <FootnoteDescription footnote={FOOTNOTE} />
          </VerticalLayout>
          <PatientFormSubheading withBottomPadding>
            {
              translations.patientForms.medicalForm.sectionTwo
                .performedTreatments
            }
          </PatientFormSubheading>

          <YesNoRadioWithLabel
            elementName="surgery_performed"
            label={
              translations.patientForms.medicalForm.sectionTwo
                .performedTreatments
            }
            formLabelSx={visuallyHidden}
            sideMutation={(_value, mutation) => {
              Object.values(OPERATION_INDEXES).forEach((operationIndex) => {
                const prefix = `operation_${operationIndex}`;
                mutation(null, `${prefix}_date`);
                mutation(null, `${prefix}_wound_closed`);
                mutation(null, `${prefix}_ops`);
              });
            }}
            subForms={{
              [PREDICAMENT_STATE_YES]: (
                <div className="flex flex-col space-y-8 py-8 pl-4">
                  {Object.values(OPERATION_INDEXES).map((operationIndex) => {
                    const prefix = `operation_${operationIndex}`;
                    return (
                      <div
                        key={operationIndex}
                        className="flex w-full space-x-8"
                      >
                        <div className="flex w-1/4 flex-col justify-between">
                          <DatePickerWithLabel
                            bold
                            elementName={`${prefix}_date`}
                            flatModel
                            label={
                              translations.patientForms.medicalForm.sectionTwo
                                .surgeryDate
                            }
                          />
                          <YesNoRadioWithLabel
                            elementName={`${prefix}_wound_closed`}
                            flatModel
                            label={
                              translations.patientForms.medicalForm.sectionTwo
                                .wound
                            }
                          />
                        </div>
                        <div className="grid w-full grid-cols-3 gap-x-4 gap-y-2 tablet:grid-cols-2">
                          <FormElementRenderProps
                            elementName={`${prefix}_ops`}
                            flatModel
                          >
                            {({ onChange, validation, value }) => {
                              const opsArray: string[] = Array.isArray(value)
                                ? [
                                    ...value,
                                    ...Array(
                                      NUMBER_OF_OPS_FIELDS - value.length,
                                    ).fill(""),
                                  ]
                                : Array(NUMBER_OF_OPS_FIELDS).fill("");

                              const handleOpsChange = (
                                index: number,
                                newValue: string,
                              ) => {
                                const updated = [...opsArray];
                                updated[index] = newValue;
                                onChange(updated);
                              };

                              // Parse per-item validation message array (if it's our custom format)
                              let perItemMessages: (string | null)[] = [];

                              try {
                                const parsed = JSON.parse(
                                  (validation as any)?.infos ?? "",
                                );
                                if (
                                  parsed?.type === "perItemErrors" &&
                                  Array.isArray(parsed.messages)
                                ) {
                                  perItemMessages = parsed.messages;
                                }
                              } catch {
                                // Not our structured format
                              }

                              // Fallback validation if it's not a structured message
                              const fallbackValidation =
                                typeof (validation as any)?.infos ===
                                  "string" &&
                                !(validation as any).infos.includes(
                                  "perItemErrors",
                                )
                                  ? validation
                                  : undefined;

                              return (
                                <>
                                  {opsArray.map((val, ii) => {
                                    const opsIndex = ii + 1;
                                    const itemError = perItemMessages[ii];

                                    return (
                                      <TextInputField
                                        key={ii}
                                        elementName={`${prefix}_ops_${opsIndex}`}
                                        label={`${translations.patientForms.medicalForm.sectionTwo.surgeryOps} ${opsIndex}`}
                                        value={val}
                                        onChange={(value) =>
                                          handleOpsChange(ii, value)
                                        }
                                        variant="outlined"
                                        validation={
                                          itemError
                                            ? { infos: itemError }
                                            : fallbackValidation
                                        }
                                        hasCustomValidation
                                      />
                                    );
                                  })}
                                </>
                              );
                            }}
                          </FormElementRenderProps>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ),
            }}
          />
          <TextAreaWithLabel
            bold
            elementName="other_treatments"
            flatModel
            label={
              translations.patientForms.medicalForm.sectionTwo.otherTreatments
            }
            marginOverride={margin(0)}
          />

          <PatientFormSubheading withBottomPadding>
            {translations.patientForms.medicalForm.sectionTwo.complications}
          </PatientFormSubheading>
          <YesNoRadioWithLabel
            elementName="complication_in_treatment_process"
            label={
              translations.patientForms.medicalForm.sectionTwo.complications
            }
            formLabelSx={visuallyHidden}
            sideMutation={(_value, mutate) => {
              complicationOptions.forEach((option) => {
                mutate(null, option.checkboxElementName);
                mutate(null, option.descriptionElementName);
              });
            }}
            startWithNo
            subForms={{
              [PREDICAMENT_STATE_YES]: (
                <VerticalLayout overflow="visible" margin={margin(0, 0, 0, 4)}>
                  <FormControl component="fieldset" sx={{ gap: sizing(1) }}>
                    <Body as="legend" style={visuallyHidden}>
                      {
                        translations.patientForms.medicalForm.sectionTwo
                          .complications
                      }
                    </Body>
                    {complicationOptions.map((option) => (
                      <CheckboxWithTextfield
                        key={option.checkboxElementName}
                        checkboxElementName={option.checkboxElementName}
                        descriptionElementName={option.descriptionElementName}
                        label={option.label}
                      />
                    ))}
                  </FormControl>
                  <RadioGroupV2
                    elementName="infections_state"
                    flatModel
                    options={infectionOptions}
                    label={
                      translations.patientForms.medicalForm.sectionTwo.germs
                    }
                    formControlLabelSx={{
                      paddingBottom: sizing(0.5),
                      marginLeft: 0,
                    }}
                    formLabelSx={{
                      padding: padding(1, 0),
                      fontSize: FONT_SIZE_14,
                    }}
                    radioSx={RADIO_WHITE_BACKGROUND}
                    sideMutation={(_value, mutation) => {
                      mutation(null, "infections");
                    }}
                  />
                </VerticalLayout>
              ),
            }}
          />
          <PatientFormSubheading withBottomPadding>
            {
              translations.patientForms.medicalForm.sectionTwo
                .longTermImpairments
            }
          </PatientFormSubheading>
          <VerticalLayout style={{ marginLeft: sizing(-1.5) }}>
            <Body>
              {translations.patientForms.medicalForm.sectionTwo.singer}
            </Body>
            <SingerAssessment
              onChange={onChange}
              datePickerProps={{
                textInputVariant: "outlined",
                inputSx: {
                  background: WHITE,
                },
              }}
              textInputProps={{
                variant: "outlined",
                style: {
                  background: WHITE,
                },
              }}
            />
          </VerticalLayout>
        </Section>
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <PatientFormsSectionHeader>
          {translations.patientForms.medicalForm.sectionThree.title}
        </PatientFormsSectionHeader>
        <Section>
          <Subheading
            as="h5"
            margin={margin(0, 0, 0.5, 0.5)}
            style={{
              padding: padding(0, 0, 1),
              fontSize: FONT_SIZE_18,
              fontWeight: FONT_WEIGHT_BOLD,
            }}
          >
            {translations.patientForms.medicalForm.sectionThree.resilience}
          </Subheading>
          <YesNoRadioWithLabel
            elementName="limited_capacity"
            label={
              translations.patientForms.medicalForm.sectionThree.resilience
            }
            formLabelSx={visuallyHidden}
            startWithNo
          />
          <PatientFormSubheading withBottomPadding>
            {
              translations.patientForms.medicalForm.sectionThree
                .musculoskeletalSystem
            }
          </PatientFormSubheading>
          <RadioGroupV2
            elementName="reha_capacity"
            label={
              translations.patientForms.medicalForm.sectionThree
                .musculoskeletalSystem
            }
            options={musculoskeletalResilienceOptions}
            formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
            formLabelSx={visuallyHidden as SxProps}
            radioSx={RADIO_WHITE_BACKGROUND}
            sideMutation={(value, mutate) => {
              if (value !== MUSCOSKELETAL_RESILIENCE.PARTIALLY) {
                return mutate(null, "reha_capacity_partial");
              }
            }}
          />
          <YesNoRadioWithLabel
            elementName="interim_prosthesis_fitted"
            label={
              translations.patientForms.medicalForm.sectionThree.prosthesis
            }
            startWithNo
          />
          <TextAreaWithLabel
            bold
            elementName="reha_capacity_other"
            label={translations.patientForms.medicalForm.sectionThree.specifics}
            marginOverride={margin(0)}
          />
          <PatientFormSubheading withBottomPadding>
            {translations.patientForms.medicalForm.sectionThree.earlyRehabIndex}
          </PatientFormSubheading>
          <ConnectedCheckboxGroup
            elementName="reha_capacity_early_rehab_criteria"
            formGroupStyle={{ gap: sizing(1) }}
            label={
              translations.patientForms.medicalForm.sectionThree.earlyRehabIndex
            }
            labelStyle={visuallyHidden}
            items={earlyRehabCriteriaOptions}
          />
        </Section>
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <PatientFormsSectionHeader>
          {translations.patientForms.medicalForm.sectionFour.title}
        </PatientFormsSectionHeader>
        <Section>
          <Subheading
            as="h5"
            margin={margin(0, 0, 0.5, 0.5)}
            style={{
              padding: padding(0, 0, 1),
              fontSize: FONT_SIZE_16,
              fontWeight: FONT_WEIGHT_BOLD,
            }}
          >
            {translations.patientForms.medicalForm.sectionFour.rehabGoals}
          </Subheading>
          <TextAreaWithLabel
            elementName="rehabilitation_goals"
            label={translations.patientForms.medicalForm.sectionFour.rehabGoals}
            formLabelSx={visuallyHidden as SxProps}
          />
        </Section>
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <PatientFormsSectionHeader>
          {translations.patientForms.medicalForm.sectionFive.title}
        </PatientFormsSectionHeader>
        <Section>
          <Subheading
            as="h5"
            margin={margin(0, 0, 0.5, 0.5)}
            style={{
              padding: padding(0, 0, 1),
              fontSize: FONT_SIZE_18,
              fontWeight: FONT_WEIGHT_BOLD,
            }}
          >
            {translations.patientForms.medicalForm.sectionFive.rehabFocus}
          </Subheading>
          <RadioGroupV2
            elementName="rehab_focus"
            label={
              translations.patientForms.generalForm.sectionI
                .accessibilityGuardianType
            }
            options={rehabFocusOptions}
            formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
            formLabelSx={visuallyHidden as SxProps}
            radioSx={RADIO_WHITE_BACKGROUND}
            sideMutation={(_value, mutation) => {
              mutation(null, "rehab_focus_other_description");
            }}
          />
          <PatientFormSubheading withBottomPadding>
            {translations.patientForms.medicalForm.sectionFive.typeOfRehab}
          </PatientFormSubheading>
          <RadioGroupV2
            elementName="recommended_reha_type"
            label={
              translations.patientForms.medicalForm.sectionFive.typeOfRehab
            }
            options={rehabTypeOptions}
            formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
            formLabelSx={visuallyHidden as SxProps}
            radioSx={RADIO_WHITE_BACKGROUND}
            sideMutation={(_value, mutation) => {
              mutation(null, "recommended_reha_type_stationary");
              mutation(null, "recommended_reha_type_ambulant_mobil");
            }}
          />
          <PatientFormSubheading withBottomPadding>
            {translations.patientForms.medicalForm.sectionFive.rehabRl}
          </PatientFormSubheading>
          <YesNoRadioWithLabel
            elementName="rehab_directive_state"
            label={translations.patientForms.medicalForm.sectionFive.rehabRl}
            formLabelSx={visuallyHidden}
            startWithNo
            sideMutation={(_value, mutate) => {
              mutate(null, "rehab_directive_type");
            }}
            required
            subForms={{
              [PREDICAMENT_STATE_YES]: (
                <CheckboxGroupV2
                  elementName="rehab_directive_type"
                  flatModel
                  formControlLabelSx={{
                    paddingBottom: sizing(0.5),
                    margin: margin(0, 0, 0, 4),
                  }}
                  formLabelSx={visuallyHidden}
                  label={
                    translations.patientForms.medicalForm.sectionFive
                      .rehabDirectiveTypeLabel
                  }
                  options={rehabTypeDirectiveOptions}
                />
              ),
            }}
          />
        </Section>
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <PatientFormsSectionHeader>
          {translations.patientForms.medicalForm.sectionSix.title}
        </PatientFormsSectionHeader>
        <Section>
          <Subheading
            as="h5"
            margin={margin(0, 0, 0.5, 0.5)}
            style={{
              padding: padding(0, 0, 1),
              fontSize: FONT_SIZE_18,
              fontWeight: FONT_WEIGHT_BOLD,
            }}
          >
            {
              translations.patientForms.medicalForm.sectionSix
                .specialRequirements
            }
          </Subheading>
          <YesNoRadioWithLabel
            elementName="special_requirements_required"
            label={
              translations.patientForms.medicalForm.sectionSix
                .specialRequirements
            }
            formLabelSx={visuallyHidden}
            startWithNo
            sideMutation={(_value, mutate) => {
              specialRehabRequirementsItems.forEach((item) => {
                mutate(null, item.elementName);
                if (item.children?.props.elementName)
                  mutate(null, item.children?.props.elementName);
              });
            }}
            subForms={{
              [PREDICAMENT_STATE_YES]: (
                <FormControl
                  component="fieldset"
                  sx={{
                    padding: padding(0, 0, 1),
                    margin: margin(0, 0, 0, 4),
                  }}
                >
                  <SubheadingFormLabel sx={visuallyHidden as SxProps}>
                    {
                      translations.patientForms.medicalForm.sectionSix
                        .specialRequirementsSelectOptionsLabel
                    }
                  </SubheadingFormLabel>
                  <FormGroup sx={{ gap: sizing(1) }}>
                    {specialRehabRequirementsItems.map((item, index) => {
                      return (
                        <VerticalLayout key={index} overflow="visible">
                          <CheckboxInputField
                            elementName={item.elementName}
                            flatModel
                            label={item.label}
                            sideMutation={(value, mutate) => {
                              if (!value) {
                                if (!item.children?.props.elementName) return;
                                return mutate(
                                  null,
                                  item.children.props.elementName,
                                );
                              }
                            }}
                          />
                          {item.children && (
                            <FormWatcher watchPath={item.elementName}>
                              {({ watchedValue }) => {
                                return (
                                  <div
                                    style={getActivableStyles(!!watchedValue)}
                                  >
                                    {item.children}
                                  </div>
                                );
                              }}
                            </FormWatcher>
                          )}
                        </VerticalLayout>
                      );
                    })}
                  </FormGroup>
                </FormControl>
              ),
            }}
          />
          <PatientFormSubheading withBottomPadding>
            {translations.patientForms.medicalForm.sectionSix.transportation}
          </PatientFormSubheading>
          <RadioGroupV2
            elementName="transport_type"
            label={
              translations.patientForms.medicalForm.sectionSix.transportation
            }
            options={transportOptions}
            formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
            formLabelSx={visuallyHidden as SxProps}
            radioSx={RADIO_WHITE_BACKGROUND}
            sideMutation={(_value, mutation) => {
              mutation(null, "transport_type_taxi_specification");
              mutation(null, "transport_type_ambulance_specification");
              mutation(null, "transport_type_ambulance_description");
            }}
            required
          />
          <YesNoRadioWithLabel
            elementName="require_accompanying_person"
            formLabelSx={{ padding: padding(GAP, 0, 1) }}
            label={
              translations.patientForms.medicalForm.sectionSix
                .accompanyingPerson
            }
            startWithNo
            required
          />
          <PatientFormSubheading>
            {
              translations.patientForms.medicalForm.sectionSix
                .contactInformationDoctor
            }
          </PatientFormSubheading>
          <SectionRow customPadding={padding(1, 0, 0)}>
            <TextInputWithLabel
              bold
              label={translations.people.firstName}
              large
              elementName="doctor_in_charge_in_hospital_first_name"
              required
            />
            <TextInputWithLabel
              bold
              label={translations.people.lastName}
              large
              elementName="doctor_in_charge_in_hospital_last_name"
              required
            />
          </SectionRow>
          <SectionRow customPadding={padding(2, 0, 0)}>
            <TextInputWithLabel
              bold
              label={
                translations.patientForms.medicalForm.sectionSix.phoneDoctor
              }
              large
              elementName="doctor_in_charge_in_hospital_phone"
              required
            />
          </SectionRow>
        </Section>
      </FormSectionsWrapper>
    </VerticalLayout>
  );
}
