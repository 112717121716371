import { VerticalLayout } from "ds_legacy/materials/layouts";
import { FormSectionsWrapper } from "dsl/ecosystems/PatientForms/components";
import { SectionEight } from "./SectionEight";
import { SectionEleven } from "./SectionEleven";
import { SectionFive } from "./SectionFive";
import { SectionFour } from "./SectionFour";
import { SectionFourteen } from "./SectionFourteen";
import { SectionNine } from "./SectionNine";
import { SectionOne } from "./SectionOne";
import { SectionSeven } from "./SectionSeven";
import { SectionSix } from "./SectionSix";
import { SectionTen } from "./SectionTen";
import { SectionThirteen } from "./SectionThirteen";
import { SectionThree } from "./SectionThree";
import { SectionTwelve } from "./SectionTwelve";
import { SectionTwo } from "./SectionTwo";
import { SectionZero } from "./SectionZero";

export function DRVGeneralFormPresenter() {
  return (
    <VerticalLayout width="100%">
      <FormSectionsWrapper>
        <SectionZero />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionOne />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionTwo />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionThree />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionFour />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionFive />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionSix />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionSeven />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionEight />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionNine />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionTen />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionEleven />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionTwelve />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionThirteen />
      </FormSectionsWrapper>
      <FormSectionsWrapper>
        <SectionFourteen />
      </FormSectionsWrapper>
    </VerticalLayout>
  );
}
