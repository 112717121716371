import { SxProps } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { AT_HOME_SITUATION, GUARDIAN, HOME_CARE_SITUATION } from "core/consts";
import RadioGroupV2, { RadioOptionV2 } from "ds_legacy/components/RadioGroupV2";
import { RADIO_WHITE_BACKGROUND } from "ds_legacy/materials/colors";
import { margin, sizing } from "ds_legacy/materials/metrics";
import { useTranslations } from "translations";

export function useLivingSituationOptions() {
  const translations = useTranslations();
  const homeOptions: RadioOptionV2[] = [
    {
      id: AT_HOME_SITUATION.ALONE,
      value: AT_HOME_SITUATION.ALONE,
      label: translations.patientForms.generalForm.sectionI.optionAtHomeAlone,
    },
    {
      id: AT_HOME_SITUATION.RELATIVES,
      value: AT_HOME_SITUATION.RELATIVES,
      label:
        translations.patientForms.generalForm.sectionI
          .optionAtHomeWithRelatives,
    },
  ];

  const homeCareSituationOptions: RadioOptionV2[] = [
    {
      id: HOME_CARE_SITUATION.HOME,
      value: HOME_CARE_SITUATION.HOME,
      label: translations.patientForms.generalForm.sectionI.optionAtHome,
      subForm: (
        <RadioGroupV2
          elementName="living_situation_at_home"
          flatModel
          formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
          formControlSx={{ margin: margin(0, 0, 0, 2) }}
          label={
            translations.patientForms.generalForm.sectionI
              .accessibilityAtHomeSituation
          }
          options={homeOptions}
          formLabelSx={visuallyHidden as SxProps}
          radioSx={RADIO_WHITE_BACKGROUND}
        />
      ),
    },
    {
      id: HOME_CARE_SITUATION.ASSISTED_LIVING,
      value: HOME_CARE_SITUATION.ASSISTED_LIVING,
      label:
        translations.patientForms.generalForm.sectionI.optionAssistedLiving,
    },
    {
      id: HOME_CARE_SITUATION.CARE_HOME,
      value: HOME_CARE_SITUATION.CARE_HOME,
      label: translations.patientForms.generalForm.sectionI.optionCareHome,
    },
  ];

  return homeCareSituationOptions;
}

export function useGuardianOptions() {
  const translations = useTranslations();
  const guardianOptions: RadioOptionV2[] = [
    {
      id: GUARDIAN.CARER,
      value: GUARDIAN.CARER,
      label: translations.patientForms.generalForm.sectionI.optionCarer,
    },
    {
      id: GUARDIAN.HEALTH_CARE_REP,
      value: GUARDIAN.HEALTH_CARE_REP,
      label: translations.patientForms.generalForm.sectionI.optionHealthCareRep,
    },
    {
      id: GUARDIAN.LEGAL_REP,
      value: GUARDIAN.LEGAL_REP,
      label: translations.patientForms.generalForm.sectionI.optionLegalRep,
    },
  ];
  return guardianOptions;
}
